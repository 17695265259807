import React, { useRef, useState } from "react";

// React Router
import { Link, useNavigate } from "react-router-dom";

// images
import { google, googlePlay, appStore } from "../constant/imagePath";

// Components
import FormInput from "../components/Forms/FormInput/FormInput";
import { Header } from "../components";
import { Help } from "../components/Models/Index";
import GoogleAuthentication from "./GoogleAuthentication/GoogleAuthentication";
import { startLoading, stopLoading } from "../Store/IdentitySlice";
// Services
import { login } from '../services/IdentityService';
import { toast } from "react-toastify";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import StorageService from "../services/StorageService";
import TwoFactorModel from "../components/Models/TwoFAModel";
import '../style/LoginStyle.css'
import '../style/SignUpStyle.css'

const Login = () => {
    const [message, setMessage] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.identity)
    const [emailOrUsernameValue, setEmailOrUsernameValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [emailOrUsernameError, setEmailOrUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [twofa, setTwofa] = useState(false);

    const signInWithBasicAuth = async (e) => {
        e.preventDefault();
        setEmailOrUsernameError("");
        setPasswordError("");
        if (emailOrUsernameValue === "" || passwordValue === "") {
            setEmailOrUsernameError("Please enter your email/username.");
            setPasswordError("Please enter your password.");
            dispatch(stopLoading())
            return;
        }
        // const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,]).{8,}$/;
        // if (!passwordRegex.test(passwordValue)) {
        //     setPasswordError("Password must be 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.");
        //     dispatch(stopLoading())
        //     return;
        // }
        const loginResponse = await login(emailOrUsernameValue, passwordValue, setTwofa);
        if (loginResponse) {
            dispatch(stopLoading())
            navigate('/')
        } else {
            dispatch(stopLoading())
        }
    }

    const signInWithGoogle = (e) => {
        e.preventDefault();
    }

    return (
        <React.StrictMode>
            {isLoading && <Loader />}
            <Header
                loginAndSignup={true}
                messageShow={false}
                cameraShow={false}
                sendIconShow={false}
            />

            <Help helpModal={message} setHelpModal={setMessage} justShow={"Mobile App will be launched soon."} />
            <div className="width-center">
            <div
                className="sm:w-[540px] w-full mx-auto sm:pt-20 pt-8 containerContent parent-signup"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <h1 className="text-[28px] text-secondary font-bold sm:mx-0">
                    Login to your Account
                </h1>
                <h2 className="text-xl text-secondary sm:mx-0">
                    Login to FilmWaffle Today!
                </h2>

                <form action="#" className="mt-5 sm:mx-0">
                    <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
                        <div>
                            <label className="text-gray block" htmlFor="email">
                                Email or username <span style={{ color: "red" }} > * </span>
                            </label>
                            <input
                                type="text"
                                name="email"
                                id="email"
                                onChange={(e) => setEmailOrUsernameValue(e.target.value)}
                                className="border border-gray rounded-md h-[34px] w-full px-2 inputField"
                                required
                                // style={{ borderColor: "rgba(209, 209, 209, 1)", background: "transparent" }}
                            />
                            {emailOrUsernameError && <span style={{ color: 'red' }}>{emailOrUsernameError}</span>}
                        </div>

                        <div>
                            <FormInput
                                label="Password"
                                id="password"
                                type="password"
                                onChange={(e) => setPasswordValue(e.target.value)} />
                            {passwordError && <span style={{ color: "red" }}>{passwordError}</span>}
                        </div>
                    </div>

                    <div className="mt-3 mb-5 flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="remember"
                                style={{ borderColor: "rgba(209, 209, 209, 1)" }}
                            />
                            <label
                                className="text-gray text-sm block mt-[2px] ml-2"
                                htmlFor="remember"
                            >
                                Remember me
                            </label>
                        </div>

                        <Link to="/password-reset-link" className="text-gray text-sm">
                            Forgot Password?
                        </Link>
                    </div>

                    <div className="custom-button-div" >
                        <button
                            className="commonButton bg-primary text-black rounded-md text-xl custom-button1"
                            onClick={signInWithBasicAuth}
                        >
                            Login
                        </button>
                        <button
                            className="commonButton bg-primary text-black rounded-md text-xl custom-button"
                        >
                            <GoogleAuthentication />
                        </button>
                    </div>
                </form>

                <div className="mt-4">
                    <p className="mb-5 text-lg text-center" style={{ fontWeight: "300", position: 'relative',Zindex: 9}}>
                        Not a member ?{" "}
                        <Link
                            className="text-primary"
                            to="/signUp"
                            style={{ fontWeight: "400" }}
                        >
                            sign up today
                        </Link>
                    </p>

                    <hr style={{ color: "rgba(255, 255, 255, 0.12)" }} />

                    <p className="text-gray text-base text-center mt-5 font-light">
                        Get The App
                    </p>

                    <div className="flex items-center justify-center gap-2 mt-2 bottomCompanyIcon">
                        <img
                            src={googlePlay}
                            alt="google play"
                            style={{ cursor: "pointer" }}
                            onClick={() => setMessage(!message)}
                        />
                        <img src={appStore} alt="app store" style={{ cursor: "pointer" }}
                            onClick={() => setMessage(!message)}
                        />
                    </div>
                </div>
            </div>
            </div>
            {twofa && <TwoFactorModel
                open={twofa}
                setTwofa={setTwofa}
            />}
        </React.StrictMode>
    );
};

export default Login;

